<template>
  <v-container fluid tag="section">
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-format-list-group-plus"
          title="Manutenção AL/SE/LD"
          inline
          class="px-5 py-4 ml-0"
        >
          <v-row
            v-if="
              this.$store.getters.getUserInfo.admin ||
                this.$store.getters.getUserInfo.validador
            "
            class="mx-auto mt-5"
          >
            <v-col cols="12">
              <v-btn
                color="primary"
                dark
                elevation="1"
                class="mt-3"
                style="float:left"
                relative
                text
                medium
                @click="$router.push('/dados/manutencao-tecnicos/importacoes')"
              >
                <v-icon left size="30px">
                  mdi-arrow-up-circle
                </v-icon>
                Importações
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mx-auto mt-6" justify="start">
            <v-col cols="6" sm="3">
              <input-month
                label="Competência de"
                rules="min:7"
                :minMonth="'2023-01'"
                :selectedMonth="competencia_de"
                @monthSelected="setCompetenciaDe"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <input-month
                label="Competência até"
                rules="min:7"
                :minMonth="'2023-01'"
                :selectedMonth="competencia_ate"
                @monthSelected="setCompetenciaAte"
              />
            </v-col>
            <v-col cols="6" sm="3">
              <v-select
                :items="arrBases"
                v-model="base"
                label="Base"
                class="mt-0 pt-1"
                hide-details
              />
            </v-col>
          </v-row>

          <v-row class="mx-auto">
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Temas selecionados
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense>
                      <v-col v-if="temasEmSelecionamento.length < 1" cols="12">
                        <strong style="color: red;">
                          Selecione pelo menos 1 tema
                        </strong>
                      </v-col>
                      <v-col class="pb-0 pt-0" cols="12">
                        <v-checkbox
                          v-model="selecionar_todos_temas"
                          label="Selecionar todos os temas"
                          hide-details
                          class="mt-0"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        class="pb-0 pt-1"
                        cols="auto"
                        v-for="temas in temas_validos[base]"
                        :key="temas.slug"
                      >
                        <v-checkbox
                          v-model="temasEmSelecionamento"
                          :label="temas.category"
                          hide-details
                          :value="temas.slug"
                        />
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        temasSelecionadosIsDirty &&
                          temasEmSelecionamento.length > 0
                      "
                      class="mt-3"
                      justify="center"
                    >
                      <v-btn
                        color="primary"
                        elevation="5"
                        small
                        :disabled="loadingDados"
                        @click="aplicarTemasSelecionados()"
                      >
                        Aplicar temas
                      </v-btn>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>

          <v-row class="mx-auto">
            <v-col cols="12">
              <card-qtd-identificadores-nao-importados
                :base="base"
                :competencia_de="competencia_de"
                :competencia_ate="competencia_ate"
                :temas_validos="temas_validos[base]"
                :temas_selecionados="temas_selecionados"
                :valores="codigosIdentificadoresNaoImportados[base] || {}"
              />
            </v-col>
          </v-row>

          <v-row class="mx-auto">
            <v-col cols="12">
              <card-erros-indicadores
                :base="base"
                :competencia_de="competencia_de"
                :competencia_ate="competencia_ate"
                :temas_validos="temas_validos[base]"
                :temas_selecionados="temas_selecionados"
                :valores="errosIndicadores[base] || []"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UsersService from "@/services/UsersService.js";
  import ManutencaoTecnicosService from "@/services/ManutencaoTecnicosService.js";

  export default {
    name: "ManutencoesTecnicosIndex",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      CardQtdIdentificadoresNaoImportados: () =>
        import(
          "@/components/manutencao-tecnicos/CardQtdIdentificadoresNaoImportados.vue"
        ),
      CardErrosIndicadores: () =>
        import("@/components/manutencao-tecnicos/CardErrosIndicadores.vue"),
    },
    data: () => ({
      loadingDados: false,
      competencia_de: "2023-12",
      competencia_ate: "2024-01",
      base: "TEC_AL",
      arrBases: [
        {
          value: "TEC_AL",
          text: "TEC_AL (Alimentadores)",
        },
        {
          value: "TEC_SUB",
          text: "TEC_SUB (Subestações)",
        },
        {
          value: "TEC_LD",
          text: "TEC_LD (Linhas de Distribuição)",
        },
      ],
      temas_validos: {
        TEC_AL: [],
        TEC_SUB: [],
        TEC_LD: [],
      },
      selecionar_todos_temas: true,
      temas_selecionados: [],
      temasEmSelecionamento: [],
      temasSelecionadosIsDirty: false,
      codigosIdentificadoresNaoImportados: {},
      errosIndicadores: {},
    }),
    computed: {
      concatedArrFilters() {
        return [
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados,
        ];
      },
    },
    async mounted() {
      await this.getIndicadoresFieldsCategories();
    },
    methods: {
      setCompetenciaDe(data) {
        this.competencia_de = data;
      },
      setCompetenciaAte(data) {
        this.competencia_ate = data;
      },
      getDados() {
        return Promise.all([
          this.getCodigosIdentificadoresNaoImportados(),
          this.getErrosIndicadores(),
        ]).catch((err) => {
          this.$toast.error("Erro ao recuperar dados dos indicadores.", "", {
            position: "topRight",
          });
          console.error(err);
        });
      },
      getIndicadoresFieldsCategories() {
        return UsersService.getIndicadoresFieldsCategories()
          .then((res) => {
            this.temas_validos["TEC_AL"] =
              res.fields_indicadores_alimentadores_categories;
            this.temas_validos["TEC_SUB"] =
              res.fields_indicadores_subestacoes_categories;
            this.temas_validos["TEC_LD"] =
              res.fields_indicadores_linhas_distribuicao_categories;

            this.setSubParametros();
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar temas dos indicadores.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getCodigosIdentificadoresNaoImportados() {
        return ManutencaoTecnicosService.getCodigosIdentificadoresNaoImportados(
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados
        )
          .then((res) => {
            this.codigosIdentificadoresNaoImportados = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      getErrosIndicadores() {
        return ManutencaoTecnicosService.getErrosIndicadores(
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados
        )
          .then((res) => {
            this.errosIndicadores = res;
          })
          .catch((err) => {
            throw err;
          });
      },
      aplicarTemasSelecionados() {
        this.temas_selecionados = this.temasEmSelecionamento;
        this.temasSelecionadosIsDirty =
          this.temasEmSelecionamento.toSorted().toString() !==
          this.temas_selecionados.toSorted().toString()
            ? true
            : false;
      },
      setSubParametros() {
        this.temasEmSelecionamento = this.temas_selecionados = this.temas_validos[
          this.base
        ].map((tema) => tema.slug);
      },
    },
    watch: {
      concatedArrFilters() {
        this.getDados();
      },
      selecionar_todos_temas() {
        this.temasEmSelecionamento = this.selecionar_todos_temas
          ? this.temas_validos[this.base].map((tema) => tema.slug)
          : [];
      },
      temasEmSelecionamento() {
        this.temasSelecionadosIsDirty =
          this.temasEmSelecionamento.toSorted().toString() !==
          this.temas_selecionados.toSorted().toString()
            ? true
            : false;
      },
      base() {
        this.setSubParametros();
      },
    },
  };
</script>

<style></style>
