import APIService from "./APIService";

export default {
  getCodigosIdentificadoresNaoImportados(
    competenciaDe,
    competenciaAte,
    temasSelecionados
  ) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `manutencao-tecnicos/get-codigos-identificadores-nao-importados?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  getErrosIndicadores(competenciaDe, competenciaAte, temasSelecionados) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get(
          `manutencao-tecnicos/get-erros-indicadores?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}`
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });
  },
  downloadCodigosIdentificadoresNaoImportados(
    competenciaDe,
    competenciaAte,
    temasSelecionados,
    base,
    config
  ) {
    return APIService.apiCall().get(
      `manutencao-tecnicos/download-codigos-identificadores-nao-importados?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&temas_selecionados=${temasSelecionados}&base=${base}`,
      {
        responseType: "blob",
        ...config,
      }
    );
  },
  downloadErrosIndicadores(
    competenciaDe,
    competenciaAte,
    base,
    config
  ) {
    return APIService.apiCall().get(
      `manutencao-tecnicos/download-erros-indicadores?competencia_de=${competenciaDe}&competencia_ate=${competenciaAte}&base=${base}`,
      {
        responseType: "blob",
        ...config,
      }
    );
  },
};
